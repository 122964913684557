<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0 d-inline">{{ pageName }}</h3>
                <div class="d-inline float-right mt--1" style="font-size: 28px; height: 28px; cursor: pointer;">
                  <b-icon
                    id="add-contact"
                    icon="plus"
                    class="rounded-circle bg-info p-2 "
                    variant="light"
                    @click="showDetailForm({}, -1, $event.target)"
                  ></b-icon>
                </div>
                <b-tooltip target="add-contact" triggers="hover">
                  緊急連絡先を追加する
                </b-tooltip>
              </b-card-header>
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(actions)="row">
                  <b-button size="sm" variant="link" @click="showDetailForm(row.item, row.index, $event.target)">
                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                  </b-button>
                  <b-button size="sm" variant="link" @click="deleteDetail(row.item)">
                    <b-icon icon="trash" variant="danger"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
              <!-- Info modal -->
              <b-modal
                id="detail-form" 
                :title="formTitle" 
                @hide="resetDetailForm"
                @ok="saveDetail"
              >
                <b-overlay :show="formLoading" rounded="md">
                  <base-form
                    :schema="detailFormSchema"
                    :layout="detailFormLayout"
                    :value="item"
                    :error="formError"
                    ref="detail_form"
                  >
                  </base-form>
                </b-overlay>
              </b-modal>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Toast } from '../../components';
import { ajax, common, config, constant } from '../../utils'
import { helper } from '../common';
import { emergency_contact_form_layout, emergency_contact_form_schema } from './schema';
export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'name', label: '氏名' },
        { key: 'phone', label: '電話番号' },
        { key: 'relationship', label: '本人との関係', class: 'text-center' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
      formTitle: '緊急連絡先を追加',
      formLoading: false,
      formError: null,
      detailFormSchema: emergency_contact_form_schema,
      detailFormLayout: emergency_contact_form_layout,
      item: {},
    }
  },
  created: function () {
    document.title = this.pageName;
    this.getContactList();
  },
  computed: {
    pageName: function() {
      return common.getPageName(this.$route.name);
    },
    totalRows: function() {
      return this.items.length;
    },
  },
  methods: {
    getContactList() {
      const vm = this;
      ajax.fetchGet(config.api.account.contact_list).then(data => {
        vm.items = data.results;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    showDetailForm(item, index, button) { // eslint-disable-line
      this.item = common.clone(item);
      if (index >= 0) {
        this.formTitle = '緊急連絡先を変更';
      } else {
        this.formTitle = '緊急連絡先を追加';
      }
      this.$root.$emit('bv::show::modal', 'detail-form', button);
    },
    resetDetailForm() {
      this.formError = null;
    },
    async saveDetail(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.detail_form.validate();
      if (isValid) {
        const vm = this;
        vm.formLoading = true;
        vm.formError = null;
        let req = null;
        if (!vm.item.id) {
          req = ajax.fetchPost(config.api.account.contact_list, vm.item);
        } else {
          req = ajax.fetchPut(common.formatStr(config.api.account.contact_detail, vm.item.id), vm.item);
        }
        req.then(() => {
          vm.getContactList();
          vm.$bvModal.hide('detail-form');
        }).catch(errors => {
          Toast.errorFromApi(errors);
          vm.formError = errors;
        }).finally(() => {
          vm.formLoading = false;
        });
      }
    },
    deleteDetail(item) {
      if (confirm(constant.CONFIRM.DELETE)) {
        const vm = this;
        vm.loading = true;
        ajax.fetchDelete(common.formatStr(config.api.account.contact_detail, item.id)).then(() => {
          Toast.success(constant.SUCCESS.DELETED);
          vm.getContactList();
        }).catch(errors => {
          Toast.errorFromApi(errors);
          vm.formError = errors;
        }).finally(() => {
          vm.loading = false;
        });
      }
    },
  },
}
</script>