import choice from "../../utils/choice";
import { api } from "../common";

export const profile_basic_info_schema = [
  {
    "name": "code",
    "type": "string",
    "label": "社員番号",
    "read_only": true,
    "max_length": 6,
    "required": true,
  },
  {
    "name": "last_name",
    "type": "string",
    "label": "姓",
    "max_length": 10,
    "required": true,
  },
  {
    "name": "first_name",
    "type": "string",
    "label": "名",
    "max_length": 10,
    "required": true,
  },
  {
    "name": "last_name_ja",
    "type": "string",
    "label": "姓(フリカナ)",
    "max_length": 30,
  },
  {
    "name": "first_name_ja",
    "type": "string",
    "label": "名(フリカナ)",
    "max_length": 30,
  },
  {
    "name": "last_name_en",
    "type": "string",
    "label": "姓(ローマ字)",
    "max_length": 30,
  },
  {
    "name": "first_name_en",
    "type": "string",
    "label": "名(ローマ字)",
    "max_length": 30,
  },
  {
    "name": "birthday",
    "type": "date",
    "label": "生年月日",
  },
  {
    "name": "country",
    "type": "choice",
    "label": "国籍・地域",
    "choices": choice.country,
  },
  {
    "name": "gender",
    "type": "choice",
    "label": "性別",
    "choices": choice.gender,
  },
  {
    "name": "marriage",
    "type": "choice",
    "label": "婚姻状況",
    "choices": choice.marriage,
  },
  {
    "name": "email",
    "type": "string",
    "label": "会社メールアドレス",
    "read_only": true,
    "required": true,
  },
  {
    "name": "private_email",
    "type": "string",
    "label": "個人メールアドレス",
  },
  {
    "name": "phone",
    "type": "string",
    "label": "携帯番号",
    "max_length": 15,
  },
  {
    "name": "post_code",
    "type": "string",
    "label": "郵便番号",
    "max_length": 7,
  },
  {
    "name": "address1",
    "type": "string",
    "label": "住所１",
    "max_length": 50,
    "help_text": "郵便番号の示す住所まで",
  },
  {
    "name": "address2",
    "type": "string",
    "label": "住所２",
    "max_length": 50,
    "help_text": "番地・号・建物名・部屋番号まで",
  },
  {
    "name": "nearest_station",
    "type": "string",
    "label": "最寄駅",
    "max_length": 20,
  },
];

export const profile_basic_info_layout = [
  'code',
  ['last_name', 'first_name'],
  ['last_name_ja', 'first_name_ja', 'last_name_en', 'first_name_en'],
  ['birthday', 'country', 'gender', 'marriage'],
  ['email', 'private_email'],
  ['phone', 'post_code'],
  ['address1', 'address2', 'nearest_station'],
];

export const profile_passport_schema = [
  {
    "name": "passport_number",
    "type": "string",
    "label": "パスポート番号",
    "max_length": 20,
  },
  {
    "name": "passport_expired_date",
    "type": "date",
    "label": "パスポート有効期限",
  },
  {
    "name": "passport_image",
    "type": "file",
    "label": "パスポートの写し",
    "verbose_name": "passport_image_name",
    "handle_download": api.viewAttachment,
  },
];

export const profile_personal_number_schema = [
  {
    "name": "personal_number",
    "type": "string",
    "label": "個人番号",
    "max_length": 12,
  },
  {
    "name": "personal_number_image",
    "type": "file",
    "label": "個人番号の写し（表面）",
    "verbose_name": "personal_number_image_name",
    "handle_download": api.viewAttachment,
  },
  {
    "name": "personal_number_image_back",
    "type": "file",
    "label": "個人番号の写し（裏面）",
    "verbose_name": "personal_number_image_back_name",
    "handle_download": api.viewAttachment,
  },
];

export const profile_basic_pension_schema = [
  {
    "name": "basic_pension_no",
    "type": "string",
    "label": "基礎年金番号",
    "max_length": 10,
  },
  {
    "name": "basic_pension_image",
    "type": "file",
    "label": "基礎年金番号の写し",
    "verbose_name": "basic_pension_image_name",
    "handle_download": api.viewAttachment,
  },
];

export const profile_insurance_schema = [
  {
    "name": "employment_insurance_no",
    "type": "string",
    "label": "雇用保険証被保険者番号",
    "max_length": 11,
  },
  {
    "name": "healthy_insurance_no",
    "type": "string",
    "label": "健康保険記号",
    "max_length": 11,
  },
  {
    "name": "healthy_insurance_sub_no",
    "type": "string",
    "label": "健康保険番号",
    "max_length": 11,
  },
  {
    "name": "healthy_insurance_image",
    "type": "file",
    "label": "健康保険の写し",
    "verbose_name": "healthy_insurance_image_name",
    "handle_download": api.viewAttachment,
  },
];

export const profile_residence_schema = [
  {
    "name": "residence_no",
    "type": "string",
    "label": "在留カード番号",
    "max_length": 20,
    "required": true,
  },
  {
    "name": "residence_type",
    "type": "choice",
    "label": "在留資格種類",
    "choices": choice.residence,
    "required": true,
  },
  {
    "name": "expired_date",
    "type": "date",
    "label": "在留カード期限",
    "required": true,
  },
  {
    "name": "residence_image",
    "type": "file",
    "label": "在留カードの写し",
    "verbose_name": "residence_image_name",
    "handle_download": api.viewAttachment,
  },
  {
    "name": "residence_image_back",
    "type": "file",
    "label": "在留カードの写し（裏面）",
    "verbose_name": "residence_image_back_name",
    "handle_download": api.viewAttachment,
  },
];

export const emergency_contact_form_schema = [
  {
    "name": "name",
    "type": "string",
    "label": "氏名",
    "required": true,
    "max_length": 20,
  },
  {
    "name": "phone",
    "type": "string",
    "label": "電話番号",
    "required": true,
    "max_length": 11,
  },
  {
    "name": "post_code",
    "type": "string",
    "label": "郵便番号",
    "max_length": 7,
  },
  {
    "name": "address1",
    "type": "string",
    "label": "住所１",
    "max_length": 100,
  },
  {
    "name": "address2",
    "type": "string",
    "label": "住所２",
    "max_length": 100,
  },
  {
    "name": "relationship",
    "type": "string",
    "label": "本人との関係",
    "max_length": 10,
  },
];

export const emergency_contact_form_layout = [
  ['name', 'phone'],
  ['relationship', 'post_code'],
  'address1',
  'address2',
];